import gql from "graphql-tag";

export const getAddresses = gql `
query getAddresses($customerAccessToken: String!){
    customer(customerAccessToken: $customerAccessToken) {
        addresses(first: 20) {
            edges {
                node {
                    zip
                    province
                    phone
                    address1
                    address2
                    city
                    country
                    firstName
                    id
                    name
                }
            }
        }
    }
}
`

export const customerAddressCreate = gql `
mutation customerAddressCreate($address: MailingAddressInput!, $customerAccessToken: String!) {
    customerAddressCreate(address: $address, customerAccessToken: $customerAccessToken) {
      customerAddress {
        id
      }
      customerUserErrors {
        message
      }
    }
  }
`

export const customerAddressUpdate = gql `
mutation customerAddressUpdate($address: MailingAddressInput!, $customerAccessToken: String!, $id: ID!) {
  customerAddressUpdate(address: $address, customerAccessToken: $customerAccessToken, id: $id) {
    customerAddress {
      id
    }
    customerUserErrors {
      message
    }
  }
}
`

export const customerAddressDelete = gql `
mutation customerAddressDelete($customerAccessToken: String!, $id: ID!) {
  customerAddressDelete(customerAccessToken: $customerAccessToken, id: $id) {
    customerUserErrors {
      message
    }
    deletedCustomerAddressId
  }
}
`