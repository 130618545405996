<template>
  <div>
    <van-panel :title="$t('请以英文或泰文填写收件人姓名和地址')">
      <div>
        <div class="contactor__name-wrapper van-hairline--bottom" style="margin: 0px 16px">
          <div>
            <van-field size="large" v-model="contact.name" :placeholder="$t('姓名')" clearable />
          </div>
          <div>
            <van-field size="large" :placeholder="$t('电话')" type="tel" v-model="contact.phone" clearable />
          </div>
        </div>
        <van-cell :title="areaTitle()" is-link @click="selectAddress" />
        <van-field size="large" autosize type="textarea" v-model="contact.address1" :placeholder="$t('收件地址')" clearable />

        <van-checkbox v-model="contact.def" checked-color="#07c160" style="padding: 10px 15px" v-if="false">
          {{ $t("设为默认收件地址") }}
        </van-checkbox>
      </div>

      <div class="contactor__action" slot="footer">
        <van-button @click="doSubmit" color="#EE502F" round :loading="submitLoading" :disabled="submitLoading" block>
          {{ $t("确 定") }}
        </van-button>
      </div>
    </van-panel>

    <van-popup v-model="addressShow" position="bottom">
      <div style="height: 500px">
        <van-search v-model="keyWord" shape="round" show-action @search="onSearch" :placeholder="$t('乡/市/省/邮编')"
          use-action-slot>
          <van-button slot="action" type="default" size="small" :loading="searchLoading" :disabled="searchLoading" round
            @click="onSearch">
            {{ $t("搜索") }}
          </van-button>
        </van-search>

        <div v-for="item in addressList" :key="item.fid" style="padding: 10px 15px" class="van-hairline--bottom"
          @click="addressSelect(item)">
          {{ item.full }}
        </div>

        <van-empty v-if="!addressList.length" image="search" :description="$t('暂无数据')" />
      </div>
    </van-popup>
  </div>
</template>

<script>
import {
  Panel,
  Field,
  Button,
  Toast,
  Cell,
  Popup,
  Search,
  Empty,
  Checkbox,
} from "vant";

import {
  searchGeo,
  customerAddressCreate,
  customerAddressUpdate,
} from "./service";
import { getShopifyCustomer } from "@/utils/token";

const rules = {
  name: {
    required: true,
    maxlength: 30,
  },
  mobile: {
    required: true,
    minlength: 10,
    maxlength: 10,
  },
  address: {
    required: true,
    nochinese: true,
    maxlength: 300,
    minlength: 12,
  },
};

export default {
  components: {
    [Cell.name]: Cell,
    [Popup.name]: Popup,
    [Panel.name]: Panel,
    [Field.name]: Field,
    [Button.name]: Button,
    [Empty.name]: Empty,
    [Search.name]: Search,
    [Toast.name]: Toast,
    [Checkbox.name]: Checkbox,
  },
  data() {
    return {
      submitLoading: false,
      searchLoading: false,
      contact: this.$route.params.address || {},
      addressShow: false,
      keyWord: "",
      geocode: null,
      addressList: [],
      errorMessages: {
        name: {
          required: this.$t("请输入姓名"),
          maxlength: this.$t("姓名最多输入30个字符"),
        },
        mobile: {
          required: this.$t("请输入电话"),
          minlength: this.$t("电话最少输入10个字符"),
          maxlength: this.$t("电话最多输入10个字符"),
        },
        address: {
          required: this.$t("请输入详细地址"),
          nochinese: this.$t("收货地址不能有汉字"),
          minlength: this.$t("地址最少输入12个字符"),
          maxlength: this.$t("地址最多输入300个字符"),
        },
      },
    };
  },
  methods: {
    areaTitle() {
      if (this.geocode) return this.geocode.full;
      else if (this.contact && this.contact.address2)
        return `${this.contact.address2} ${this.contact.zip}`;
      else return this.$t("请选择省市区");
    },
    addressSelect(item) {
      this.addressShow = false;
      this.geocode = item;
    },
    onSearch() {
      this.searchGeo();
    },
    async searchGeo() {
      this.searchLoading = true;
      const res = await searchGeo(this.keyWord);

      if (res.success) {
        this.addressList = res.data;
      } else Toast.fail(res.message);

      this.searchLoading = false;
    },
    selectAddress() {
      this.addressShow = true;
    },
    doReset() {
      this.contact = { def: 0 };
    },

    validate(content, rule, errorMsg) {
      if (!content || content.length === 0) {
        Toast(errorMsg.required);
        return false;
      }

      if (rule.nochinese) {
        if (/.*[\u4E00-\u9FA5]+.*$/.test(content)) {
          Toast(errorMsg.nochinese);
          return false;
        }
      }

      if (content.length < rule.minlength) {
        Toast(errorMsg.minlength);
        return false;
      }

      if (content.length > rule.maxlength) {
        Toast(errorMsg.maxlength);
        return false;
      }
      return true;
    },

    async doSubmit() {
      const name = this.contact.name;
      if (!this.validate(name, rules.name, this.errorMessages.name)) return;

      const mobile = this.contact.phone;
      if (!this.validate(mobile, rules.mobile, this.errorMessages.mobile))
        return;

      if (!mobile.startsWith(0)) {
        Toast(this.$t("电话号码必须以0开头10位数字"));
        return;
      }

      if (!this.geocode && !this.contact.address2) {
        Toast(this.$t("请选择省市区"));
        return;
      }

      const address = this.contact.address1.replaceAll("\\", "/");
      if (!this.validate(address, rules.address, this.errorMessages.address))
        return;

      this.submitLoading = true;

      if (this.contact.id)
        this.customerAddressUpdate({ name, mobile, address });
      else this.customerAddressCreate({ name, mobile, address });
    },
    async customerAddressUpdate({ name, mobile, address }) {
      const shopifyCustomer = getShopifyCustomer();
      if (!shopifyCustomer) return;

      const { shopifyCustAccessToken } = shopifyCustomer;

      let province, zip, city, address2;

      if (this.geocode) {
        const {
          engProvince,
          postcode,
          engCity,
          full,
          cityname,
          areaname,
          townname,
        } = this.geocode;
        province = engProvince;
        zip = postcode;
        city = areaname;
        address2 = `${townname} ${areaname} ${cityname}`;
      } else if (this.contact) {
        province = this.contact.province;
        zip = this.contact.zip;
        city = this.contact.city;
        address2 = this.contact.address2;
      }

      const payload = {
        address: {
          address1: address,
          address2,
          city,
          country: "TH",
          firstName: name,
          phone: mobile,
          province,
          zip,
        },
        customerAccessToken: shopifyCustAccessToken,
        id: this.contact.id,
      };

      const { customerAddressUpdate: data } = await customerAddressUpdate(
        payload
      );
      this.submitLoading = false;
      const { customerAddress, customerUserErrors } = data;
      if (customerAddress) {
        Toast.success(this.$t("修改地址成功"));
        this.$router.go(-1);
      }

      if (customerUserErrors && customerUserErrors.length > 0) {
        const { message } = customerUserErrors[0];
        Toast.fail(message);
      }
    },
    async customerAddressCreate({ name, mobile, address }) {
      const shopifyCustomer = getShopifyCustomer();
      if (!shopifyCustomer) return;

      const { shopifyCustAccessToken } = shopifyCustomer;
      const {
        engProvince,
        engCity,
        postcode,
        full,
        cityname,
        areaname,
        townname,
      } = this.geocode;
      const payload = {
        address: {
          address1: address,
          address2: `${townname} ${areaname} ${cityname}`,
          city: areaname,
          country: "TH",
          firstName: name,
          phone: mobile,
          province: engProvince,
          zip: postcode,
        },
        customerAccessToken: shopifyCustAccessToken,
      };
      const { customerAddressCreate: data, message } =
        await customerAddressCreate(payload);

      if (message) {
        Toast.fail(message);
        return;
      }

      const { customerAddress, customerUserErrors } = data;

      if (customerAddress) {
        Toast.success(this.$t("新增地址成功"));
        this.$router.go(-1);
      }

      if (customerUserErrors && customerUserErrors.length > 0) {
        const { message } = customerUserErrors[0];
        Toast.fail(message);
      }
      this.submitLoading = false;
    },
  },
};
</script>
<style lang="less" scoped>
.contactor {
  padding: 10px;
  background-color: #ffffff;
}

.no-border {
  border-top-width: 100px !important;
}

.contactor__name-wrapper {
  display: flex;
}

.contactor__def {
  padding: 15px 15px;
}

.contactor__def-label {
  font-size: 14px;
}

.contactor__action {
  text-align: right;
}

.van-cell {
  align-items: center;
}

.province {
  font-size: 14px;
  padding: 10px 16px;
}

.contactor__name-wrapper {
  .van-cell {
    padding: 10px 0px;
  }
}

:deep(.van-overlay) {
  left: 50%;
  transform: translate(-50%, 0);
  max-width: 500px;
}

:deep(.van-popup) {
  max-width: 500px;
  left: 50%;
  transform: translate(-50%, 0);
}

:deep(.van-action-sheet__content::-webkit-scrollbar) {
  display: none;
  /* Chrome Safari */
}
</style>