import {
    query,
    mutate
} from "@/utils/apollo-utils.js"
import {
    getAddresses as getAddressesGql,
    customerAddressCreate as customerAddressCreateGql,
    customerAddressUpdate as customerAddressUpdateGql,
    customerAddressDelete as customerAddressDeleteGql
} from "./gqls.js"
import axios from '@/utils/request';

export async function getAddresses(customerAccessToken) {
    return query(getAddressesGql, {
        customerAccessToken
    })
}

export async function searchGeo(keyWord) {
    return axios.get('contact/searchGeo', {
        keyword: keyWord
    });
}

export async function customerAddressCreate(payload) {
    return mutate(customerAddressCreateGql, payload)
}

export async function customerAddressUpdate(payload) {
    return mutate(customerAddressUpdateGql, payload)
}

export async function customerAddressDelete(payload) {
    return mutate(customerAddressDeleteGql, payload)
}